import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { Box } from '@mui/material';
import { Redirect } from 'react-router';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Breadcrumbs, Preloader, TopBar } from '../../components';
import { InviteUsersTableHeader, DeleteInvitedUserModal, InviteUserModal, EditAccessModal } from './components';
import { getCrumbs, getInstallerColumns, getViewerColumns, getPropMgmtColumns } from './utils/helpers';
import { resendToEmail } from '../SettingProfile/slice';
import i18n, { transformLanguageForAPI } from '../../i18n';

import './index.scss';
import { useGetInvitedUsersQuery, useInvitedUserMutation, useUpdateInvitedUserPermissionMutation } from './services';
import { addNotification } from '../NotificationGenerator/slice';
import { clearUserData, getUserInfo } from '../UserDetails/slice';
import { isInvitedUserHasReadOnlyPermissionSelector, isPvEmployeeInstallHasReadOnlyAccessSelector } from '../../redux-store/selectors/userDevices';

const InvitedUsers = (props) => {
  const { location } = props;
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(0);

  const { userId, myRoleType } = location.state || {};

  useEffect(() => {
    if (myRoleType !== 'end_user') {
      dispatch(getUserInfo({ userId }));
    }

    return () => {
      dispatch(clearUserData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: invitedUsers, isLoading, refetch: refetchInvitedUsers } = useGetInvitedUsersQuery({ endUserId: userId });
  const [updateInvitedUserPermission] = useUpdateInvitedUserPermissionMutation();
  const [inviteUser, { error: inviteUserError }] = useInvitedUserMutation();
  const crumbs = useMemo(() => getCrumbs(userId, myRoleType), [userId, myRoleType]);
  const isReadOnlyPermission = useSelector(isInvitedUserHasReadOnlyPermissionSelector);
  const isPvEmployeeInstallHasReadOnlyAccess = useSelector(isPvEmployeeInstallHasReadOnlyAccessSelector);

  useEffect(() => {
    if (inviteUserError) {
      dispatch(addNotification({ type: 'error', text: i18n.t(inviteUserError.message) }));
    }
  }, [dispatch, inviteUserError]);

  const resendEmail = useCallback((userEmail) => dispatch(resendToEmail({
    email: userEmail,
    language: transformLanguageForAPI(i18n.language),
    cb: () => refetchInvitedUsers()
  })), [dispatch, refetchInvitedUsers]);

  const viewerColumns = useMemo(() => getViewerColumns(myRoleType, isReadOnlyPermission || isPvEmployeeInstallHasReadOnlyAccess, resendEmail), [myRoleType, isReadOnlyPermission, isPvEmployeeInstallHasReadOnlyAccess, resendEmail]);
  const installerColumns = useMemo(() => getInstallerColumns(myRoleType, isReadOnlyPermission || isPvEmployeeInstallHasReadOnlyAccess), [myRoleType, isPvEmployeeInstallHasReadOnlyAccess, isReadOnlyPermission]);
  const propMgmtColumns = useMemo(() => getPropMgmtColumns(myRoleType, isReadOnlyPermission || isPvEmployeeInstallHasReadOnlyAccess), [myRoleType, isPvEmployeeInstallHasReadOnlyAccess, isReadOnlyPermission]);

  const tableProps = {
    className: '-highlight m-datatable__table',
    pageSize: 1000,
    getTdProps: () => ({ style: { margin: 0, padding: '10px 0', marginLeft: 25 } }),
    getTheadThProps: () => ({ style: { margin: 0, padding: '10px 0', marginLeft: 25 } }),
    noDataText: i18n.t('noInvitedUsers'),
    loading: false
  };

  const inviteUserHandler = (data) => {
    inviteUser({ data, endUserId: userId });
  };

  const updInvitedUserPermission = (data) => {
    updateInvitedUserPermission({ data, endUserId: userId });
  };

  if (!userId) {
    return <Redirect to="/users" />;
  }

  return (
    <>
      <DeleteInvitedUserModal userId={userId} />
      <InviteUserModal
        onSubmit={inviteUserHandler}
        initialValues={{ permission: 'read' }}
        // we do not need to show permission field for property management
        hidePermissions={tabIndex === 2}
      />
      <EditAccessModal handleSubmit={updInvitedUserPermission} />
      <TopBar updateButton showNavigation />

      <Box sx={{ p: 2 }} className="invited-users-wrapper">
        <Breadcrumbs crumbs={crumbs} />
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(idx) => setTabIndex(idx)}
        >
          <TabList>
            <Tab>
              <div className="row tabs-cont align-items-center">
                <div className="col-auto mr-auto">
                  <div className="m-portlet__head-tools">
                    <div className="nav nav-tabs m-tabs-line m-tabs-line--danger m-tabs-line--2x m-tabs-line--right nav-tab-links marginBottom6px">
                      <div className="nav-item m-tabs__item">
                        <div className="nav-link m-tabs__link">{i18n.t('viewers')}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab>
              <div className="row tabs-cont align-items-center">
                <div className="col-auto mr-auto">
                  <div className="m-portlet__head-tools">
                    <div className="nav nav-tabs m-tabs-line m-tabs-line--danger m-tabs-line--2x m-tabs-line--right nav-tab-links marginBottom6px">
                      <div className="nav-item m-tabs__item">
                        <div className="nav-link m-tabs__link">{i18n.t('installers')}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab>
              <div
                className="row tabs-cont align-items-center"
                style={{
                  display: ['root', 'solar_admin', 'oem', 'oem_employee', 'pv_installer', 'pv_installer_employee', 'viewer'].includes(myRoleType) || (['end_user'].includes(myRoleType) && invitedUsers?.propertyManagements?.length) ? undefined : 'none'
                }}
              >
                <div className="col-auto mr-auto">
                  <div className="m-portlet__head-tools">
                    <div className="nav nav-tabs m-tabs-line m-tabs-line--danger m-tabs-line--2x m-tabs-line--right nav-tab-links marginBottom6px">
                      <div className="nav-item m-tabs__item">
                        <div className="nav-link m-tabs__link">{i18n.t('propMgmt')}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </TabList>
          <TabPanel>
            {(!isReadOnlyPermission && !isPvEmployeeInstallHasReadOnlyAccess) && (<InviteUsersTableHeader myRoleType={myRoleType} invitedRole="viewer" />)}
            {isLoading ? (<Preloader />) : (
              <ReactTable {...tableProps} columns={viewerColumns} data={invitedUsers.viewers} />
            )}
          </TabPanel>
          <TabPanel>
            {(!isReadOnlyPermission && !isPvEmployeeInstallHasReadOnlyAccess) && (<InviteUsersTableHeader myRoleType={myRoleType} invitedRole="installer" />)}
            {isLoading ? (<Preloader />) : (
              <ReactTable {...tableProps} columns={installerColumns} data={invitedUsers.installers} />
            )}
          </TabPanel>
          <TabPanel>
            {['root', 'solar_admin'].includes(myRoleType) && !invitedUsers?.propertyManagements?.length && (
              <InviteUsersTableHeader myRoleType={myRoleType} invitedRole="property_management" />
            )}
            {isLoading ? (<Preloader />) : (
              <ReactTable {...tableProps} columns={propMgmtColumns} data={invitedUsers.propertyManagements} />
            )}
          </TabPanel>
        </Tabs>
      </Box>

    </>
  );
};

InvitedUsers.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired
};

export default InvitedUsers;
